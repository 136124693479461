<template>
	<b-row>
		<b-col cols="8" class="mx-auto">
			<div v-if="loading" class="loading">
				<!-- <div class="effect-1 effects"></div>
				<div class="effect-2 effects"></div>
				<div class="effect-3 effects"></div> -->
			</div>
			<b-card v-else header-class="border-bottom mb-1">
				<template #header>
					<div class="flex-fill d-flex justify-content-between">
						<div class="">
							<h4 class="card-title">Complaint report</h4>
							<p class="p-title mt-25 mb-0">Project code ({{ complaint.project.SP_PROJID }})</p>
						</div>
						<div class="d-flex justify-content-end flex-column ">
							<b-badge variant="primary">{{ complaint.closed_date ? 'Colsed' : 'New' }}</b-badge>
							<p class="p-title mt-25 mb-0">2021 / 4 / 12</p>
						</div>
					</div>
				</template>

				<p class="font-weight-bold">Complainant</p>
				<div class="pl-50 d-flex justify-content-between">
					<div class="">
						<div class="">
							<p class="p-title mb-25">Name</p>
							<p class="font-weight-bold">{{ complaint.name }}</p>
						</div>
					</div>
					<div class="">
						<div class="">
							<p class="p-title mb-25">Gender</p>
							<p class="font-weight-bold">{{ complaint.gender.name }}</p>
						</div>
					</div>
					<div class="">
						<div class="">
							<p class="p-title mb-25">Phone</p>
							<p class="font-weight-bold">{{ complaint.phone }}</p>
						</div>
					</div>
				</div>
				<hr />
				<div class="">
					<p class="mb-50 font-weight-bold">Complaint text</p>
					<p class="p-75 border rounded" style="background-color: #f9f9f9">{{ complaint.complaint }}</p>
					<p class="mb-50 mt-3 font-weight-bold">Complaint category</p>
					<div v-if="complaint.category" class="pl-50">
						<p class="mb-25">{{ complaint.category.name }}</p>
						<p class="ml-50"><feather-icon icon="CornerDownRightIcon" /> {{ complaint.sub_category.name }}</p>
					</div>
					<div v-else class="">Not categorized yet!</div>
				</div>
				<hr class="mt-2" />
				<div class="">
					<p class="mb-2 font-weight-bold">All responses to the complaint</p>
					<app-timeline v-if="complaint.responses.length > 0">
						<app-timeline-item v-for="(response, i) in complaint.responses" :key="i">
							<div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
								<h6>{{ response.user }}</h6>
								<small class="text-muted">22 May, 2021 03:00 PM</small>
							</div>
							<div class="mt-25">
								<p class="p-75 rounded">{{ response.response }}</p>
							</div>
						</app-timeline-item>
					</app-timeline>
					<div v-else class="pl-25">
						There is no responses to this complaint yet.
					</div>
				</div>
			</b-card>
		</b-col>
	</b-row>
</template>

<script>
import store from '@/store';
import { BCard, BBadge, BRow, BCol } from 'bootstrap-vue';
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue';
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue';

export default {
	name: 'ComplaintReport',

	components: { BCard, BBadge, BRow, BCol, AppTimelineItem, AppTimeline },

	props: {
		complaintId: {
			type: [Number, String],
			required: true,
		},
	},

	data: () => ({
		complaint: undefined,
		loading: false,

		categories: [],
	}),

	created() {
		this.loadComplaint();
	},

	methods: {
		async loadComplaint() {
			try {
				this.loading = true;
				this.categories = await store.dispatch('categories/getCategories');
				this.complaint = await store.dispatch('complaints/getComplaint', parseInt(this.complaintId, 10));
			} catch (error) {
				console.error(error);
			}

			this.loading = false;
		},
	},
};
</script>

<style lang="scss" scoped>
.p-title {
	font-size: 12px;
}

.p-title + p {
	font-size: 16px;
}
</style>
